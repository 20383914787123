import {API_KEY_API, NO_API_KEY_URLS} from '@/urls';
import store from '@/store/store';
import * as ACTION from '@/store/actionTypes';
import Vue from 'vue';
import {ERROR} from '@/const';

/**
 * Middleware for request api
 */
class RequestMiddleware {
  #requestList = {};

  /**
   *  Methods to temporary save active request link and return if request duplicated
   * @param {function} cb
   * @return {(function(...[*]): (*))|*}
   */
  checkDuplication(cb) {
    const self = this;

    return function(rest) {
      const hash = JSON.stringify(rest);
      if (self.#requestList[hash]) {
        return self.#requestList[hash];
      }

      const requestPromise = cb(rest);
      self.#requestList[hash] = requestPromise;

      requestPromise.catch(() => {}).finally(() => {
        delete self.#requestList[hash];
      });

      return requestPromise;
    };
  }

  /**
   *
   * @param {object} requestConfig
   * @return {object}
   */
  async checkApiKey(requestConfig) {
    let url = requestConfig.url;
    if (NO_API_KEY_URLS.includes(url.split('?')[0])) {
      return requestConfig;
    }
    if (!Vue.$storage.get('api_key')) {
      await this.getApiKey();
    }
    const apiKey = Vue.$storage.get('api_key');
    if (!apiKey) {
      return Promise.reject(new Error(ERROR.NO_API_KEY));
    }

    const params = requestConfig.data;
    // add api key to url
    if (requestConfig.method === 'get' || requestConfig.method === 'delete') {
      const tempUrl = new URL(url, location.origin);
      tempUrl.searchParams.set('api_key', apiKey);
      url = tempUrl.href.split(location.origin).join('');
    } else {
      // add api key to params
      params instanceof FormData ?
          params.set('api_key', apiKey) :
          Object.assign(params, {
            api_key: apiKey,
          });
    }
    return {
      ...requestConfig,
      url,
      data: params,
    };
  }

  /**
   * Set new api key to request
   * @param {object} requestConfig
   * @return {object}
   */
  updateApiKey(requestConfig) {
    let checkedUrl = requestConfig.url;
    const data = requestConfig.data;

    // update api key in url
    if (checkedUrl.includes('api_key') && !checkedUrl.startsWith(API_KEY_API)) {
      checkedUrl = new URL(checkedUrl, location.origin);
      checkedUrl?.searchParams?.set('api_key', Vue.$storage.get('api_key'));
    }

    // update api key in request data
    if (data instanceof FormData) {
      data.has('api_key') && data.set('api_key', Vue.$storage.get('api_key'));
      data.has('channel_key') && data.set('channel_key`', Vue.$storage.get('channel_key'));
    } else if (data.hasOwnProperty('api_key')) {
      data['api_key'] && (data['api_key'] = Vue.$storage.get('api_key'));
    }
    return {
      ...requestConfig,
      url: checkedUrl.href.split(location.origin).join(''),
      data,
    };
  }

  /**
   * Request new api and channel key if it's need.
   * @return {Promise<never>}
   */
  async getApiKey() {
    if (!Vue.$storage.get('api_key')) {
      try {
        const channelKey = Vue.$storage.get('channel_key') ||
            await store.dispatch(ACTION.GET_CHANNEL_KEY);
        await store.dispatch(
            ACTION.GET_API_KEY,
            {channel_key: channelKey},
        );
      } catch (err) {
        throw new Error(err || ERROR.NO_API_KEY);
      }
    }
  }

  /**
   * Mapped request data to url
   * @param {object} params
   * @param {string} method
   * @param {string} url
   * @return {{params, url: string}}
   */
  prepareParams({
    params,
    method,
    url,
  }) {
    if (method === 'get' || method === 'delete') {
      const encodedUriStr = Object.entries(params).map(([prop, val]) => {
        if (Array.isArray(val)) {
          return params[prop].map((value) => `${prop}[]=${value}`).join('&');
        }
        return `${encodeURIComponent(prop)}=${encodeURIComponent(val)}`;
      }).join('&');
      url = encodedUriStr ? `${url}?${encodedUriStr}` : url;
    }
    return {
      params,
      url,
    };
  }
}

export default new RequestMiddleware();
